import logo from './logo.svg';
import './App.css';
import TypeIt from "typeit-react";

const Strong = ({ children }) => {
  return <strong style={{ fontSize: "80px" }}>{children}</strong>;
};


function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <TypeIt getBeforeInit={(instance) => {
          instance.type("Hi,").pause(200).type(" I'm Ryan. ").pause(1000).type("<br/>").pause(400).type("I make software. ");
          // Remember to return it!
          return instance;
        }}
        />
      </header>
    </div>
  );
}

export default App;
